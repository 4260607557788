.SortList {

	&__tabsMessage {
		fab-color: theme;
		box-sizing: border-box;
		margin: 0 0 8px;
		padding: 3px 15px;
		text-align: center;
		width: 100%;

		.CurvedArrow {
			height: 27px;
			margin-right: 10px;
			top: 0;
			width: 27px;

			&:before {
				border-top-width: 15px;
				border-right-width: 15px;
				border-right-color: fab-color('theme');
			}

			&:after {
				border-top-color: fab-color('theme');
				border-top-width: 8px;
				top: 0;
				height: 13px;
				width: 18px;
			}
		}
	}

	&__tabsMessage-fields {
		fab-color: gray10;
		margin: 14px 0 10px;
	}

	&__sortSection-title {
		fab-color: gray7;
		fab-font-size: large;
		fab-font-weight: semibold;
		fab-line-height: medium;
		font-family: unset;
	}

	&__sectionIcon {
		fab-fill: gray7;
		display: none;
	}

	&__sortSection {
		padding: 7px 13px 13px;
		margin: 0;

		~ .SortList__sortSection {
			margin-top: 21px;

			&:before {
				fab-background-color: gray3;
				left: 13px;
				top: -12px;
				width: calc(100% - 26px);
			}
		}

		.SortList__list {
			min-height: unset;
		}

		&.ui-sortable-helper {
			display: block;

			.SortList__sectionIcon {
				fab-fill: gray7;
				display: block;
				margin-right: 10px;
			}

			.SortList__sortSection-title {
				fab-font-size: medium;
				fab-border-color: gray6;
				align-items: center;
				border-radius: 2px;
				display: inline-flex;
				box-shadow: 0 1px 3px rgba(153,153,153,0.7);
				padding: 7px 6px 7px 10px;
			}
		}

		.SortList__listItem {
			fab-background-color: gray1;
			fab-border-color: gray4;
			fab-font-size: medium;
			fab-line-height: medium;
			box-sizing: border-box;
			padding: 6px 12px 7px !important;
			width: 100%;

			&:hover:not(.js-SortList__listItem--disabled) {
				fab-background-color: gray3;
				fab-border-color: gray6;
			}

			&.ui-sortable-helper {
				border-style: dashed;
			}

			&.js-SortList__listItem--disabled {
				fab-background-color: white;
				fab-color: gray5;
				border: none;
				padding: 0 12px 0 0 !important;
			}
		}

	}

	&__listItem {
		border-radius: 2px;
		font-family: unset;

		&-editable {
			fab-color: gray10;
			fab-font-size: medium;
			fab-font-weight: semibold;
			fab-line-height: medium;
			font-family: unset;
			margin: 0;
			padding: 6px 16px 8px;
		}
	}

}

.Sortlist {
	&--inline {
		flex: 0 0 auto;
		height: 100%;
		margin: 0;

		.reorderTabs1 {
			padding: 36px 0 0 100px;
		}

		&-more {
			padding: 36px 100px 0 0;

			&__title {
				fab-background-color: gray1;
				fab-color: gray10;
				fab-line-height: medium;
				border: 1px solid fab-color(gray1);
				border-radius: 2px;
				margin-top: 8px;
				padding: 6px 16px 8px;
				width: auto;
			}
		}

		.SortList__listItem {
			fab-background-color: gray1;
			fab-border-color: gray4;
			margin-right: 12px !important;

			&:hover:not(.js-SortList__listItem--disabled) {
				fab-background-color: gray3;
				fab-border-color: gray6;
			}

			&.ui-sortable-helper {
				height: 36px !important;
			}
		}

		.SortList__verticalListWrapper {
			fab-input-focus();
			border-radius: 2px;
			margin: 10px 100px 4px 0;
			padding: 17px;
			position: relative;
			width: auto;

			.SortList__listItem {
				margin: 0 0 6px 0 !important;

				&.ui-sortable-helper {
					width: calc(100% - 34px) !important;
				}

				&.js-SortList__listItem--disabled {
					margin: 0 !important;
				}

				&:not(.js-SortList__listItem--disabled) + .js-SortList__listItem--disabled {
					margin-top: 8px !important;
				}

				&:last-child {
					margin: 0 !important;
				}
			}
		}
	}
}

.alt {

	.js-SortList__listItem--disabled {
		background-color: transparent;
		border: none;
		margin: 0 !important;

		.SortList__listItem-editable {
			fab-color: gray5;
			fab-font-weight: regular;
			padding: 6px 16px 6px 0;
		}

		&:last-child {
			.SortList__listItem-editable {
				padding: 6px 16px 0 0;
			}
		}
	}

}
