// Note keep the require in this format for the layout stylus file, otherwise it breaks our global layout styles
@require 'layout.styl';
@require './education';
@require './dependents';
@require './note';
@require './table';

// All types of ba-Dropdowns get brand color for selected/active option
.ba-Select,
.ba-inlineSelect,
.ba-Dropdown,
.ba-MenuDropdown,
.ba-NestedDropdown,
.ba-NestedSelect,
.ba-LargeSelect,
.ba-TextSelect,
.ba-SmallSelect,
.ba-TitleSelect {
	&__option {
		&--active {
			fab-background-color: theme;
		}
	}
}
