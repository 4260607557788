.Quickform {
	.fab-FormNote {
		margin-left: 8px;

		&.ssn-field--error {
			display: inline-block;
			max-width: 350px;
			width: auto;
		}
	}
}
