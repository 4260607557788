@require 'hover-hint.styl';
@require 'layout.styl';
@require 'masked-value.styl';
@require 'required.styl';
@require 'text-area-counter.styl';
@require 'typeahead.styl';
@require 'view.styl';

/*
 * TODO: remove if this is added to a Fabric package (styles, forms)
 * for clearing user agent styles on input[type="search"]
 */
input[type="search"] {
	/* clears the 'X' from Internet Explorer */
	&::-ms-clear {
		display: none;
		height: 0;
		width : 0;
	}
	&::-ms-reveal {
		display: none;
		width : 0;
		height: 0;
	}
	/* clears the 'X' from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.fab-FormSection .BhrTable__scrollWrapper {
	&:not(:last-child) {
		margin-bottom: 16px;
	}

	&:last-child:before {
		background-color: white;
		bottom: -1px;
		content: '';
		display: block;
		height: 1px;
		position: absolute;
		width: 100%;
	}
}
