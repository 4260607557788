.SiteHeader {
	align-items: center;
	fab-background-color: gray2;
	display: flex;
	height: 80px;
	margin: 0 0 3px 0;
	padding: 0 32px;
	position: relative;
	right: 0;
	left: 0;
	{$fab-PageWidth--min};

	&::after {
		content: '';
		fab-gradient: theme-lightest theme-lighter;
		height: 3px;
		position: absolute;
		bottom: -3px;
		left: 0;
		right: 0;
	}

	&--dark {
		fab-background-color: gray9;

		.SiteBrand__logo {
			fab-fill(white);
		}

		.SiteNavigation__link {
			fab-color: white;

			&:before {
				fab-color: white;
			}

			&:hover,
			&:focus {
				fab-color: white;
				fab-background-color: gray8;
			}

			&--active {
				fab-background-color: gray8;
			}
		}

		.SiteSearchWrapper:before {
			background-image: linear-gradient(270deg, $fabColors.hex.gray9 55%, rgba($fabColors.hex.gray9, 0) 100%);
		}
	}
}

.SiteBrand {
	display: inline-flex;
	min-width: auto;
	// .SiteBrand__link
	&__link {
		align-items: center;
		display: flex;
	}
	// .SiteBrand__logo
	&__logo {
		max-width: 300px;
		height: auto;
	}
	.bambooHRHeaderLogo {
		fab-fill('theme');
	}
}

.SiteNavigation {
	align-items: center;
	display: flex;
	flex: 1;
	height: 100%;
	min-width: auto;
	// .SiteNavigation__link
	&__link {
		align-items: center;
		justify-content: center;
		fab-color: gray7;
		display: flex;
		fab-font-size: large;
		fab-font-weight: medium;
		fab-line-height: medium;
		padding: 0 16px;
		transition: padding 0.2s ease-in-out;
		height: 100%;
		white-space: nowrap;
		&:first-of-type {
			margin-left: 36px;
		}
		// .SiteNavigation__link:hover,
		// .SiteNavigation__link:focus
		&:hover,
		&:focus {
			fab-background-color: gray1;
			fab-color: theme-base;
			text-decoration: none;
		}
		// .SiteNavigation__link--active
		&--active {
			fab-background-color: gray1;
			fab-color: theme-base;
			fab-font-weight: heavy;
		}

	}
}

.SiteManageNav {
	align-items: center;
	justify-content: flex-end;
	display: flex;
	height: 36px;
	position: relative;
	width: auto;
	min-width: auto;
	// .SiteManageNav__item
	&__item {
		background-color: transparent;
		border: 0;
		fab-fill('gray6');
		padding: 0;
		min-width: 24px;
		margin-left: 20px;
		// .SiteManageNav__item:hover
		&:hover {
			cursor: pointer;
		}
		// .SiteManageNav__item:hover,
		// .SiteManageNav__item:active,
		// .SiteManageNav__item:focus,
		// .SiteManageNav__item--active
		&:hover,
		&:active
		&:focus,
		&--active,
		&--open {
			fab-color: theme;
			fab-fill('theme');
		}
	}
	// .SiteManageNav__textLink
	&__textLink {
		margin-left: 20px;
		overflow: hidden;
		white-space: nowrap;
	}
	// .SiteManageNav__avatar
	&__avatar {
		// .SiteManageNav__avatar--dark
		&--dark {
			border: 2px solid fab-color(white);
		}
	}
}

.ManageNavItem {
	&Dropdown {
		fab-background-color: 'white';
		border: solid 1px fab-color(theme-lighter);
		border-radius: 2px;
		box-shadow: 0 0 0 2px fab-color(theme-lightest35);
		box-sizing: border-box;
		display: none;
		position: absolute;
		right: 0;
		top: calc(100% + 4px);
		z-index: 2000;
		&__item {
			&Count {
				fab-color(gray7);
				margin-left: 6px;
			}
			&Icon {
				align-items: center;
				display: inline-flex;
				fab-fill('theme');
				justify-content: flex-start;
				width: 22px;

				&--center {
					margin-right: 8px;
					min-width: 14px;
					width: auto;
					justify-content: center;
				}
			}
			&Gray_Icon {
				align-items: center;
				display: inline-flex;
				fab-fill('gray7');
				justify-content: flex-start;
				width: 22px;

				&--center {
					margin-right: 8px;
					min-width: 14px;
					width: auto;
					justify-content: center;
				}
			}
			// ManageNavItemDropdown__itemLink
			&Link {
				align-items: center;
				display: flex;
				fab-color(gray10);
				fab-font-size(medium);
				fab-line-height: small;
				min-height: 32px;
				padding: 0 9px;
				text-align: left;
				user-select: none;
				&--button {
					border: none;
					width: 100%;

					&--white {
						fab-background-color: white;
					}
				}
				&--zendesk {
					align-items: flex-start;
					.ManageNavItemDropdown__itemIcon {
						margin-top: 15px;
					}

					.ManageNavItemDropdown__itemText {
						margin-top: 10px;
						max-width: 256px;
						white-space: normal;
					}
				}
				&:hover {
					fab-background-color: theme;
					cursor: pointer;
					fab-color(white);
					text-decoration: none;
					.ManageNavItemDropdown__itemCount {
						fab-color(white);
					}
					.ManageNavItemDropdown__itemIcon {
						fab-fill('white');
					}
					.ManageNavItemDropdown__itemText-detail {
						fab-color(white);
					}
				}
				// Legacy fix for account dropdown
				// Remove when legacy greenhouse goes away
				+legacy() {
					&:hover {
						fab-color(white);
					}
				}
			}
			&Gray_Link {
				align-items: center;
				display: flex;
				fab-color(gray7);
				fab-font-size(medium);
				fab-line-height: small;
				min-height: 32px;
				padding: 0 9px;
				text-align: left;
				user-select: none;
				&:hover {
					fab-background-color: theme;
					cursor: pointer;
					fab-color(white);
					text-decoration: none;
					.ManageNavItemDropdown__itemGray_Icon {
						fab-fill('white');
					}
				}
			}
			&Text-detail {
				fab-color: gray8;
				fab-font-size: teenie;
				fab-line-height: teenie;
				margin-bottom: 12px;
				margin-top: 2px;
			}
		}
		&--account {
			min-width: 310px;
		}
		&__header {
			background-color: fab-color(gray1);
			display: flex;
			padding: 10px 9px;
			&Avatar {
				border-radius: 50%;
				margin: 4px 16px 4px 0;
			}
			&EmployeeInfo {
				margin-top: 9px;
			}
			&Heading {
				fab-font-size: large;
				fab-line-height: medium;
				fab-font-weight: semibold;
				display: block;
			}
			&Info {
				flex: 1;
				margin-top: 1px;
				text-align: left;
			}
		}
		&__search {
			position: relative;
			&Box {
				border: 0;
				box-sizing: border-box;
				font-family: unset;
				fab-font-size: small;
				fab-line-height: small;
				height: 30px;
				padding: 0 13px 0 33px;
				width: 100%;
				&:placeholder {
					fab-color: gray6;
				}
			}&Icon {
				fab-fill: theme;
				left: 13px;
				position: absolute;
				top: 9px;
			}
		}
		&--inbox {
			min-width: 210px;
		}
		&--open {
			display: block;
		}
		&--section {
			border-top: 1px solid fab-color(gray3);
			padding: 4px 0;

			&:empty {
				border: 0;
				padding: 0;
			}
		}
		&--noBorderTop {
			border-top: 0;
		}
	}
}

.SiteSearch {
	align-items: center;
    display: flex;
	height: 36px;
	min-width: 160px;
	position: relative;
	margin-right: 20px;
	margin-left: 22px;
	// .SiteSearchWrapper
	&Wrapper {
		position: absolute;
		right: 0;
		top: 0;

		&:before {
			content: '';
			display: block;
			background-image: linear-gradient(270deg, $fabColors.hex.gray2 55%, rgba($fabColors.hex.gray2, 0) 100%);
			position: absolute;
			top: -22px;
			width: 110px;
			left: -60px;
			height: 80px;
		}
	}
	// .SiteSearch__clear
	&__clear {
		fab-fill('gray6');
		position: absolute;
		top: 9px;
		right: 12px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s ease-in-out;
		&:hover {
			cursor: pointer;
		}
		// .SiteSearch__clear--show
		&--show {
			opacity: 1;
			pointer-events: auto;
		}
	}
	// .SiteSearch__icon
	&__icon {
		background-color: transparent;
		border-radius: 18px 0 0 18px;
		fab-fill('gray6');
		height: 34px;
		left: 1px;
		position: absolute;
		top: 1px;
		padding: 0 6px 0 8px;
		pointer-events: none;
		display: flex;
		align-items: center;
		transition: all 0.2s ease-in-out;
	}
	// .SiteSearch__input
	&__input {
		border: 1px solid transparent;
		border-radius: 18px;
		box-sizing: border-box;
		fab-background-color: white;
		fab-color: gray10;
		fab-font-size: large;
		font-family: inherit;
		height: 36px;
		padding: 0 0 0 37px;
		position: relative;
		transition: all 0.2s ease-in-out;
		width: 160px;
		-webkit-appearance: none;
		// Hides default clear button in IE/Edge
		&::-ms-clear {
			display: none;
		}
		// .SiteSearch__input:hover,
		// .SiteSearch__input:focus
		&:hover,
		&:focus {
			fab-background-color: 'white';
		}
		// .SiteSearch__input:focus,
		// .SiteSearch__input--focused
		&:focus,
		&--focused {
			box-shadow: 0 0 0 2px fab-color(theme-lightest35);
			border-color: fab-color(theme-lighter);
			outline: 0;
			width: 228px;
			// .SiteSearch__input:focus + .SiteSearch__icon,
			// .SiteSearch__input--focused + .SiteSearch__icon
			+ .SiteSearch__icon {
				fab-background-color: gray1;
				fab-fill('theme');
			}
		}
		// .SiteSearch__input--blurred
		&--blurred {
			fab-color: gray6;
		}
		// .SiteSearch__input::placeholder
		&::placeholder {
			fab-color: gray6;
			transition: color 0.2s ease-in-out;
			transition-delay: 0.05s;
		}
		// IE11
		&:-ms-input-placeholder {
			fab-color: gray6;
			font-family: inherit;
			transition: color 0.2s ease-in-out;
			transition-delay: 0.05s;
		}
		// Edge
		&::-webkit-input-placeholder {
			fab-color: gray6;
			transition: color 0.2s ease-in-out;
			transition-delay: 0.05s;
		}
	}
	// .SiteSearch__results
	&__results {
		top: 42px;
		max-height: calc(100vh - 85px);
		overflow-y: auto;
	}
}

@media only screen and (min-width: 1800px) {
	.SiteNavigation__link {
		padding: 0 20px;
	}
}

@media only screen and (max-width: 1200px) {
	.SiteBrand__logo {
		max-width: 200px;
	}

	.SiteSearch {
		min-width: 46px;
		margin-right: 4px;
		&__input {
			width: 46px;

			&::placeholder {
				fab-color: white;
			}
			// IE11
			&:-ms-input-placeholder {
				fab-color: white;
			}
			// Edge
			&::-webkit-input-placeholder {
				fab-color: white;
			}

			&:hover::placeholder {
				fab-color: 'white';
			}

			&:not(:focus) + .SiteSearch__icon  {
				left: 7px;
			}

			&:focus,
			&--focused {
				fab-color: gray10;

				&::placeholder {
					fab-color: gray6;
				}

				&:-ms-input-placeholder {
					fab-color: gray6;
				}

				&::-webkit-input-placeholder {
					fab-color: gray6;
				}
			}

			&--blurred {
				color: transparent;
			}
		}
	}

	.SiteManageNav__item {
		margin-left: 16px;
	}
}
