@require './history';

.fab-Table {
	&__cell {
		&&--group {
			.BhrTableSection {
				&__artboard {
					.Quickform & {
						// TODO: This will go away when table groups support icons
						display: inline-block;
						margin-right: 2px;
					}
				}
			}
		}
	}
}
