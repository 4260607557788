.EmployeeTraining {

	&__attachedFilesLabel {
		fab-color('gray8');
		fab-font-size('small');
		fab-line-height('small');
	}

	&__attachedFiles {
		display: block;
	}

	&__files {
		max-width: 500px;

		.file-wrapper:first-child {
			margin-top: 0;
		}
	}

}

.TaskManager {
	fab-border-color('gray4');
	fab-color(background, 'gray1');
	padding: 16px 20px;

	&__details--completed {

		.TaskManager__itemWrapper {
			flex-wrap: wrap;
		}
	}

	&__header {
		margin: 0 0 17px;

		&.TaskManager__header--complete {
			+jade() {
				align-items: center;
				border-bottom: 2px solid fab-color('gray4');
				display: flex;
				justify-content: space-between
				padding: 16px 0 12px;
			}

			&.clearfix:after,
			&.clearfix:before {
				content: none;
			}
		}
	}

	&__brandedBar {
		fab-color(background, 'theme');
		border-radius: 2px 2px 0 0;
	}

	&__taskList {
		fab-border-color('gray4');
	}

	&__title--complete {
		margin: 0;
	}

	&__actions {
		display: flex;
	}

	&__actionWrapper {
		align-items: center;
		display: flex;

		button {
			height: auto;
		}
	}

	&__summary {
		margin-top: 14px;
	}

	&__summaryItem {
		fab-color(color, 'gray7');
		fab-font-size('small');
	}

	&__jadeBlankIcon {
		fab-color(fill, 'gray5');
		display: block;
		margin-bottom: 10px;
	}

	div&__filter {
		margin-left: 12px;
		width: auto;

		&--small {
			width: auto;
			margin-left: 12px;
		}
	}

	&__emptyMsg {
		+jade() {
			fab-color(color, 'gray7');
			fab-font-size('h3');
			fab-font-weight('semibold');
			box-sizing: border-box;
			height: 177px;

			&--complete {
				fab-color('gray7');
				fab-font-size('small');
				fab-line-height('teenie');
				height: auto;
				padding: 11px 0 8px;
			}
		}
	}

	&__detailsInfoItem {
		+jade() {
			align-items: center;
			display: flex;
			margin-bottom: 16px;
			padding: 0;
		}
	}

	&__detailsIcon {
		+jade() {
			fab-color(fill, 'theme');
			position: unset;
			margin-right: 12px;
			transform: none;
		}
	}


	&__itemWrapper {
		display: flex;
		padding: 22px 29px 29px 24px;

		+jade() {
			.itemDetail__title {
				padding: 1px 0 0;
				margin-right: 12px;
			}

			.itemDetail__details {
				flex-grow: 1;
				margin: 0;
			}

			.FileCard--display {
				fab-border-color('gray4');
				fab-color(background-color, 'gray1');
				border-radius: 2px;
				margin: 5px 16px 5px 0;

				&:hover {
					fab-color(background-color, 'gray2');
				}
			}

			.FileCard__title {
				fab-color(color, 'info');
				font-family: unset;

				&:hover {
					fab-color(color, 'theme');
				}
			}

			.FileCard__info {
				fab-color(color, 'gray7');
			}

			.FileCard__image {
				margin-right: 5px;
			}
		}
	}

	&__itemTitle {
		fab-color('gray10');
		margin: 0 0 10px;

		&--complete {
			fab-color(color, 'gray7');
		}
	}

	&__detailsIconText {
		fab-font-weight('semibold');
		margin: 0;

		&.TaskManager__detailsIconLink {
			fab-color(color, 'info');

			&:hover {
				fab-color(color, 'theme');
			}
		}
	}

	&__itemFrequency {
		fab-color(color, 'gray8');
		fab-font-weight('regular');
	}

	&__detailsDescription {
		fab-color(color, 'gray8');
		white-space: pre-line;
		margin: 0 0 20px 0;
	}

	&__filesList {
		+jade() {
			fab-border-color('gray3');
			margin: 0;
			padding-top: 24px;
			width: calc(100% - 35px);
		}
	}

	&__detailsInfo {
		+jade() {
			margin-bottom: 24px;
		}
	}

	&__completeInfo {
		+jade() {
			border-radius: 2px;
			border: none;
			display: flex;
			fab-color(background-color, 'gray2');
			flex-direction: column;
			margin: 0 0 24px;
			padding: 20px 20px 22px 50px;
			width: 100%;

			&Items {
				padding-top: 0;
			}

			&Item {
				margin: 16px 0 0;
			}

			&Item--notes {
				padding-top: 16px;
				margin: 0;
			}
		}
	}

	&__completeInfoItemLabel {
		+jade() {
			fab-color(color, 'gray8');
			fab-line-height('teenie');
		}
	}

	&__completeInfoItemValue {
		word-break: break-all;
		white-space: normal;
	}

	&__completeIcon {
		fab-color(fill, 'success');
		padding-top: 2px;
		margin-right: 8px;
	}

	&__completeTitle {
		fab-color(color, 'success');
		margin: 0;

		+jade() {
			display: flex;
			position: relative;
			right: 30px;
		}
	}

}

.TrainingList {
	position: relative;

	&:first-child {
		.TrainingList__category {
			border-top-right-radius: 0;
		}
	}

	&__category {
		fab-font-size('teenie');
		fab-color(background-color, 'gray2');
		fab-color(color, 'gray9');
		border: none;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		position: absolute;

		+ li.List__item {
			+jade() {
				padding-top: 33px;
			}
		}
	}

	&__item {
		display: flex;
		justify-content: space-between;
		padding: 13px 17px 13px 14px;

		+ .List__item-note {
			margin-left: 8px;
		}
	}

	&__itemInfoWrapper {
		align-items: center;
		display: flex;
	}

	&__upcomingCheckbox {
		margin: 0 8px 2px 0;
	}

	&__tasksCompleted.tasks-completed {
		+jade() {
			border-bottom: 1px solid fab-color('gray3');
			margin-bottom: 7px;
			padding: 7px 0 19px;

			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}

			.List__category {
				fab-font-size('teenie');
				fab-font-weight('semibold');
				background-color: unset;

				+ .List__item {
					margin-top: 21px;
					padding-top: 0;
				}
			}

			.List__item-note {
				fab-color(color, 'gray7');
			}

			.List__item-title {

				&:hover {
					fab-color(color, 'theme');
				}
			}

			.List__item--divider {
				border-bottom: none;
				margin: 10px 0 0;
			}

			.TrainingList__item {
				position: relative;
			}
		}
	}

	&__utilityIconWrapper {
		bottom: calc(50% - 17px);
		position: absolute;
		right: 0;
	}
}

.TrainingItem {
	&__contentContainer {
		display: flex;
	}

	&__contentWrapper {
		+jade() {
			margin: 0 0 0 10px;
		}
	}
}

.tabContent {
	.tabTrainingWrapper.tabHeader {
		+jade() {
			padding: 17px 0 11px;
		}
	}

	.jadeTrainingIcon {
		fab-color(fill, 'theme');
		margin-right: 8px;
	}

	.tabTrainingIcon {
		+jade() {
			margin: 0;
			padding-top: 0;

			&:before {
				content: none;
			}
		}
	}
}