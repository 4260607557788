.employee_education {
	clear: both;
	position: relative;

	.educationAdd {
		margin-top: 10px;
	}

	> .repeatItem:not(:last-child) {
		border-bottom-style: solid;
		width: 100%;
	}

	> .repeatItem:not(.repeatPrototype) + .repeatItem {
		padding-top: 10px;
	}
}
