.employee_dependents {
	clear: both;
	position: relative;

	.repeatItem:not(.repeatPrototype) {
		padding-top: 16px;
	}

	.repeatItem:not(.repeatPrototype):not(:nth-child(2)) {
		padding-top: 39px;
	}

	.repeatItem:not(:last-child) {
		border-bottom-style: solid;
		width: 100%;
	}
}
