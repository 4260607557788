elem = BEM('SiteFooter');

// .SiteFooter
{elem('^')} {
	background-color: fab-color(white);
	display: flex;
	flex-direction: column;
	min-height: 92px;
	position: relative;
	fab-text: teenie;
	fab-color: gray7;
	border: none;
	z-index: 1000;

	// .SiteFooter__container
	{elem('container')} {
		$slideDuration = 0.4s;
		$fadeDuration = 0.1s;

		display: flex;
		align-items: flex-start;
		box-sizing: border-box;
		transition-property: transform, opacity;
		transition-duration: $slideDuration, $fadeDuration;
		transition-timing-function: ease-in-out;
		transition-delay: 0s, $slideDuration - $fadeDuration;
	}

	// .SiteFooter__container--main
	{elem('container', 'main')} {
		box-sizing: border-box;
		display: block;
		padding-top: 24px;
		border-top: 1px solid fab-color(gray3);
		fab-background-color: 'white';
	}

	// .SiteFooter__container--actions
	{elem('container', 'actions')} {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		transform: translateY(100%);
		opacity: 0;
	}

	// .SiteFooter__wrapper
	{elem('wrapper')} {
		display: flex;
		align-items: center;
	}

	// .SiteFooter__wrapper
	{elem('wrapper', 'outer')} {
		width: auto;
		justify-content: space-between;
	}

	// .SiteFooter__wrapper--outerReverse
	{elem('wrapper', 'outerReverse')} {
		justify-content: space-between;
	}

	// .SiteFooter__link
	{elem('link')} {
		// .SiteFooter__link:not(:hover)
		&:not(:hover) {
			fab-color: gray7;
		}
	}

	// .SiteFooter__link--logo
	{elem('link', 'logo')} {
		fab-fill: gray7;

		// .SiteFooter__link--logo:hover
		&:hover {
			fab-fill: gray8;
		}
	}

	// .SiteFooter__info
	{elem('infoAlign')} {
		display: flex;
	}
}

// .SiteFooter--open
{elem('^', 'open')} {
	// .SiteFooter__container--main
	{elem('container', 'main')} {
	}

	// .SiteFooter__container--actions
	{elem('container', 'actions')} {
		transform: translateY(0);
		transition-delay: 0s, 0s;
		opacity: 1;
	}
}

// .SiteFooter--hidden
{elem('hidden')} {
	visibility: hidden;
}

// .SiteFooter--large-theme
{elem('^', 'large-theme')} {
	// .SiteFooter__container--actions
	{elem('container', 'actions')} {
		padding-top: 24px;
		padding-bottom: 32px;
	}
}

// .SiteFooter--biggie-theme
{elem('^', 'biggie-theme')} {
	// .SiteFooter__container--actions
	{elem('container', 'actions')} {
		padding-top: 32px;
		padding-bottom: 48px;
	}
}


// .SiteFooter--light-theme
{elem('^', 'light-theme')} {
	// .SiteFooter__container--actions
	{elem('container', 'actions')} {
		fab-background-color: gray1;
		border-top: 1px solid fab-color(gray3);
	}
}

// .SiteFooter--dark-theme
{elem('^', 'dark-theme')} {
	// .SiteFooter__container--actions
	{elem('container', 'actions')} {
		fab-background-color: gray10;
		box-shadow: 0 -1px 4px 0 rgba(#0, 0.3);

		// .SiteFooter__link--logo
		{elem('link', 'logo')} {
			fab-fill: gray5;

			// .SiteFooter__link--logo:hover
			&:hover {
				fab-fill: gray4;
			}
		}
	}

	// .SiteFooter__link--logo
	{elem('link', 'logo')} {
		fab-fill: gray7;

		// .SiteFooter__link--logo:hover
		&:hover {
			fab-fill: gray8;
		}
	}
}

@media screen and (max-width: 1199px) {
	{elem('^')} {
		min-width: 100vw;

		{elem('container')} {
		}

		// .SiteFooter__container--main
		{elem('container', 'main')} {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			padding-top: 24px;
		}

		// .SiteFooter__wrapper--outerReverse
		{elem('wrapper', 'outerReverse')} {
			justify-content: space-between;
		}

		// .SiteFooter__wrapper
		{elem('wrapper', 'outer')},{elem('wrapper', 'outerReverse')} {
			min-width: 100%;
			padding: 0 50px;
			box-sizing: border-box;
		}

		// .SiteFooter__wrapper--hidden
		{elem('wrapper', 'hidden')} {
			display: inline;
		}

	}
}

@media screen and (max-width: 740px) {
	{elem('^')} {
    	height: 109px;

		{elem('container')} {
		}

		// .SiteFooter__container--main
		{elem('container', 'main')} {
			padding-top: unset;
		}

		// .SiteFooter__wrapper--outer
		{elem('wrapper', 'outer')} {
			display: flex;
			flex-direction: column-reverse;
		}

		{elem('wrapper', 'outer')}>{elem('wrapper', 'left')}{
			margin-top: 12px;
		}

		// .SiteFooter__wrapper--outerReverse
		{elem('wrapper', 'outerReverse')} {
			display: flex;
			flex-direction: column;
			margin: 20px 0;
		}

		{elem('wrapper', 'outerReverse')}>{elem('wrapper', 'right')}{
			margin-top: 12px;
		}

		// .SiteFooter__wrapper--left
		{elem('wrapper', 'left')} {
			width: 100%;
			flex-direction: column;
			line-height: 20px;
		}

		// .SiteFooter__wrapper--hidden
		{elem('wrapper', 'hidden')} {
			display: none;
		}

		// .SiteFooter__link--logo
		{elem('link', 'logo')} {
		}

		// .SiteFooter__action:not(.SiteFooter__action--primary)
		.SiteFooter__action:not(.SiteFooter__action--primary) {
			display: none;
		}

		// .SiteFooter__action--primary
		{elem('action', 'primary')} {
			justify-content: center;
			width: 100%;
		}
	}

	// .SiteFooter--biggie-theme
	{elem('^', 'biggie-theme')} {
		// .SiteFooter__container--actions
		{elem('container', 'actions')} {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}