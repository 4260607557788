.HoverHint {
	align-items: center;
	display: inline-flex;
	// .HoverHint__content
	&__content {
		align-items: center;
		display: none;
		margin-left: 8px;
	}
	// .HoverHint__icon
	&__icon {
		fab-fill: theme;
		margin-right: 4px;
	}
	// .HoverHint__link
	&__link {
		fab-font-size(teenie);
	}
}

.BhrForms--view {
	.hasNote .HoverHint {
		&:hover .HoverHint__content,
		.HoverHint__content:hover {
			display: none;
		}
	}
	// .BhrForms--view .HoverHint:hover .HoverHint__content
	.HoverHint:hover .HoverHint__content {
		display: inline-flex;
	}
}
