.CalendarPicker {

	&__rangeRow {
		align-items: flex-end;
		display: flex;
	}

	&__rangeColumn {
		display: block;
	}

	&__rangeSeparator {
		line-height: 28px;
		margin: 0 7px;
	}

	&__popover {
		fab-color(border-color, 'theme');
		box-shadow: 0 0 0 2px fab-color('theme-lightest35');
	}

}

input.CalendarPicker__input ~ .CalendarPicker__toggleButton {
	box-shadow: none;
	height: calc(100% - 2px);
	line-height: normal;
	margin: 0;
	min-width: 0;
	right: 1px;
	top: 1px;
	transform: none;
	border: none;
	cursor: pointer;

	&:focus {
		right: 1px;
		padding-right: 6px;
	}

}
input.CalendarPicker__input:disabled ~ .CalendarPicker__toggleButton {
	cursor: default;
}

.BhrForms input.CalendarPicker__input {
	margin-top: 0;
}

.CalendarPicker__rangeColumn + .CalendarPicker__rangeColumn > .fab-Label {
	padding-left: 22px;
}

.CalendarGrid {

	&Manager {
		&__previousButton, &__nextButton {
			background-color: fab-color('white');
			padding-top: 4px;
			height: 32px;
		}

		&__gridTitle {
			font-family: unset;
		}

		.CalendarGridManager {

			&__gridHeaderDropdown {

				> a {
					background-color: fab-color('white');
				}

			}

		}

		&__gridHeader {
			height: initial;
			padding: 10px 12px;

			&Content {
				.chzn-container-single {
					&:first-of-type {
						width: 75px !important;
					}

					&:last-of-type {
						width: 80px !important;
					}

					b {
						width: 25px;
						fab-color(background-color, 'gray1');
					}

					&:not(.small) {
						.chzn-single {
							b {
								background-position: 4px 3px;
							}

							span {
								margin-top: 3px;
							}

						}

					}
					.chzn-single {
						height: 32px;

						div {
							right: 7px;
						}

					}

					.chzn-drop {
						width: calc(100% - 2px) !important;
					}

				}

			}

		}

	}

	&__daysOfWeek {
		display: flex;
		justify-content: center;
		padding-bottom: 0;
	}

	&__cell {

		&--withValue {

			&:not(.CalendarGrid__cell--disabled) {

				&:hover {
					background-color: fab-color('theme-lightest10');
					border-bottom-right-radius: 2px;

					&:before {
						border-color: fab-color('theme');
						border-radius: 2px;
					}

				}

			}

		}

		&--selected {
			fab-color(background-color, theme);
			border-bottom-right-radius: 2px;

			&:before {
				border-color: fab-color('theme');
				border-radius: 2px;
			}

		}

		&--now {
			&:before {
				border-color: fab-color('gray4');
				border-radius: 2px;
			}
		}

		&--disabled {
			fab-color('gray6');
		}

		&--inRange:not(.CalendarGrid__cell--selected) {
			background-color: fab-color('theme-lightest10');

			&:before {
				border-color: fab-color('theme-lightest50');
				border-left-color: fab-color('theme-lightest35');
			}

		}

	}

}

.CalendarGrid__cell--selected.CalendarGrid__cell--now {
	&:before {
		border-color: fab-color('theme');
		border-radius: 2px;
	}
}

.CalendarGrid__cell--selected.CalendarGrid__cell--now.CalendarGrid__cell--inRange {
	fab-color(background-color, 'theme');
}
