/*
	Note: Quickform renders whitespace between fieldBox elements.
	The left margin should be 8px, but is 4px to compensate.
*/
.Quickform {
	.fieldBox {
		display: inline-block;
		vertical-align: bottom;

		+ .fieldBox:not(.masked) {
			margin-left: 4px;
			&.spaced {
				margin-left: 12px;
			}
		}
	}
	.fieldRow {
		&--compensation-rate {
			color: initial;
			margin-bottom: 16px;
			display: block;	
		}	
	}
	.percentChange {
		align-items: center;
		color: fab-color(gray8);
		display: flex;
		font-size: 14px;
		font-weight: 400;
		line-height: 19px;
		margin: auto 17px;

		&-percent {
			margin-right: 5px;
		}
		&-icon {
			font-size: unset;
			margin-left: 4px;

			&-increase {
				fill: fab-color(success);
				transform: rotate(180deg);
			}
			&-decrease {
				fill: fab-color(danger);
			}
		}
		&.nextLine {
			margin: 8px 0 3px;
			width: 100%;
		}
	}
	.CurrencyPicker {
		display: inline-flex;	
	}
	.fab-InputWrapper--wrap {
		flex-wrap: wrap;
	}

	.currencyChange-message {
		height: 70px;
		padding: 10px 14px;
		display: flex;
		justify-content: start;
		align-items: center;
		box-sizing: border-box;
		column-gap: 13px;
		background-color: rgb(230, 245, 254);
		border-radius: 4px;
		width: 435px;
		margin-top: 15px;
	}

	.currencyChange-message--icon {
		fill: rgb(7, 114, 179);
		flex-shrink: 0;
	}

	.currencyChange-message--text {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		line-height: 18px;
	}

	.currencyChange-message--title {
		font-weight: 600;
		font-size: 15px;
		color: #222;
	}

	.currencyChange-message--description {
		font-weight: 400;
		font-size: 14px;
		color: #444;
	}
}
