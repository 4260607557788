/**
 * Jade replacement for Toggles.mod.styl
 * (will eventually be replaced by Fabric styles)
 *
 * NOTE for Fabric: any default styles you see here apply to what the
 * style_guide calls a "checkbox" toggle, which is now infrequently used and
 * UX seems to be moving away from.
 */

.bhrToggle,
.bhrToggleSmall {
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	position: relative;

	*, *:before, *:after {
		box-sizing: border-box;
		cursor: pointer;
	}

	input {
		height: 1px;
		opacity: 0;
		position: absolute;
		width: 1px;

		&:checked {
			& + .bhrToggle__label {
				background-color: rgba(fab-color(success), 0.2);

				&:before {
					opacity: 1;
				}

				&:after {
					opacity: 0;
				}
			}

			& ~ .bhrToggle__handle {
				left: 22px;
			}
		}
	}

	&__label {
		background-color: fab-color(gray2);
		border: 1px solid fab-color(gray5);
		border-radius: 2px;
		display: block;
		font-size: 10px;
		height: 16px;
		position: relative;
		transition: background-color 0.2s ease, color 0.2s ease;
		width: 32px;
		fab-line-height: medium !important;


		&:before {
			color: fab-color(success);
			content: attr(data-on); // heads up: not accessible
			left: 3px;
			opacity: 0;
			position: absolute;
			top: -4px;
		}
		&:after {
			color: fab-color(gray6);
			content: attr(data-off); // heads up: not accessible
			position: absolute;
			right: 3px;
			top: -4px;
		}
	}

	&__handle {
		background-color: fab-color(white);
		border: 1px solid fab-color(gray5);
		border-radius: 2px;
		display: block;
		height: 16px;
		left: 0;
		position: absolute;
		top: 0;
		transition: left 0.2s ease;
		width: 10px;
	}

	// Checkbox for indicating that a requested form field will be required or not
	&--require {
		.bhrToggle__label {
			background-color: fab-color(gray5);
			border: none;
			height: 16px;
			width: 32px;

			// square handle
			&:before {
				background-color: fab-color(white);
				border-radius: 2px;
				content: '';
				height: 14px;
				left: 1px;
				opacity: 1;
				position: absolute;
				top: 1px;
				transition: left 0.2s ease;
				width: 13px;
			}

			// "required" asterisk
			&:after {
				color: fab-color(white);
				content: '*';
				font-family: sans-serif;
				font-size: 24px;
				font-weight: bold;
				left: 18px;
				position: absolute;
				top: 2px;
				transition: left 0.2s ease;
			}
		}

		input {
			&:checked + .bhrToggle__label {
				background-color: fab-color(theme-base);
				box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);

				&:before {
					left: 18px;
				}

				&:after {
					left: 4px;
					opacity: 1;
				}
			}
		}

		.bhrToggle__handle {
			display: none;
		}
	}

	/**
	 * Fabric text type toggle
	 * e.g. Draw / Type toggle for signatures
	 */
	&--btn {
		input {
			&:checked {
				& + .bhrToggle__label {
					background: none;
					fab-color: white;
					fab-font-weight: bold;
				}

				& ~ .bhrToggle__handle {
					left: 0;
				}
			}

			&:first-of-type:checked {
				& ~ .bhrToggle__handle:before {
					left: 3px;
				}
			}

			&:last-of-type:checked {
				& ~ .bhrToggle__handle:before {
					left: 50%;
				}
			}

			&:focus {
				& ~ .bhrToggle__handle {
					border-color: fab-color(theme-lighter);
					box-shadow: 0 0 0 2px fab-color(theme-lightest35);
				}
			}
		}

		.bhrToggle__label {
			background: none;
			border: none;
			display: inline-flex;
			fab-color: gray7;
			fab-font-weight: medium;
			fab-font-size: large;
			vertical-align: middle;
			flex: 1;
			height: 100%;
			justify-content: center;
			min-width: 64px;
			padding: 8px;
			text-align: center;
			text-transform: capitalize;
			width: auto;
			z-index: 1;

			&:before,
			&:after {
				content: none;
			}

			&:first-of-type {
				left: 3px;
			}

			&:last-of-type {
				right: 2px;
			}
		}

		.bhrToggle__handle {
			border: 1px solid fab-color(gray4);
			border-radius: 2px;
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
			height: calc(100% - 2px);
			left: 0;
			padding: 3px;
			top: 1px;
			width: 100%;
			box-sizing: border-box;

			&:before {
				background-color: fab-color(theme-base);
				border: none;
				border-radius: 2px;
				box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
				content: '';
				height: calc(100% - 6px);
				position: absolute;
				top: 3px;
				transition: left 0.2s ease;
				width: calc(50% - 3px);
			}
		}

		.BhrForms--view & {
			.bhrToggle__handle {
				border-color: fab-color(gray3);
				box-shadow: none;

				&:before {
					background-color: fab-color(gray6);
				}
			}
		}

		&--disabled {
			cursor: default;
			pointer-events: none;

			.bhrToggle__handle {
				border-color: fab-color(gray3);
				box-shadow: none;

				&:before {
					background-color: fab-color(gray4);
				}
			}
		}
	}
}

/**
* Currency Toggle (Fabric "medium" size)
*/
.bhrToggleSmall {
	display: inline-block;

	.bhrToggle__label {
		fab-text: medium;
		min-width: auto;
		padding: 7px;
		width: 28px;
	}

	.bhrToggle__handle:before {
		width: 26px;
	}
}


/**
 * .roundToggle (Fabric "small" size)
 * used in PTO policy settings table
 */
input.roundToggle {
	height: 1px;
	opacity: 0;
	position: absolute;
	width: 1px;
}

input.roundToggle + label {
	background-color: fab-color(gray5);
	border-radius: 9px;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	height: 18px;
	position: relative;
	transition: all 0.2s ease;
	width: 32px;
}

input.roundToggle + label:before {
	content: '';
	background-color: fab-color(white);
	border-radius: 8px;
	box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: block;
	height: 16px;
	left: 1px;
	position: absolute;
	top: 1px;
	transition: left 0.2s ease;
	width: 16px;
}

input.roundToggle:checked + label {
	background-color: fab-color(theme-base);
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

input.roundToggle:checked + label:before {
	box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.2);
	left: 15px;
}
