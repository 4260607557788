
/*------------------------------------*\
$SORTABLE LIST (DRAG ABD DROP)

styles used for sortable lists
\*------------------------------------*/

.SortList { line-height: 1; }
.SortList__list { min-height: 37px; }

.SortList__Message,
.ui-dialog .SortList__Message {
	font-family: "BhrHeaderFont", "Trebuchet MS";
	padding: 0 20px 0 !important;
	margin-bottom: 8px !important;
	color: #777;
	font-size: 17px;
}

body[data-feature-toggles~="jade"] .SortList__Message {
	font-family: unset;
	color: inherit;
	font-size: initial;
}

.SortList__listItem {
	background: #F7F7F7;
	border: 1px dashed #B0B0B0;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	cursor: move;
	font-family: "BhrHeaderFont", "Trebuchet MS";
	font-size: 14px;
	font-weight: 600;
	margin: 8px 0 0 !important;
	padding: 10px 10px !important;
	position: relative;
	width: 400px;

	/* Pretty transitions */
	-webkit-transition: background-color .2s ease;
	-moz-transition: background-color .2s ease;
	-ms-transition: background-color .2s ease;
	-o-transition: background-color .2s ease;
	transition: background-color .2s ease;
	/* Disable Selection of text on items */
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.SortList__listItem-editable {
	padding: 2px 4px;
	margin: 5px 8px;
}
.SortList__listItem.edit .SortList__listItem-editable {
	-webkit-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
}

.SortList__listItem-editable:focus {
	background-color: #EFEFEF;
	box-shadow: 0 0 1px 0 #D2D2D2;
	cursor: text;
	text-overflow: clip;
}

.SortList__listItem-remove {
	background-color: #fff;
	color: #929292;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 1px solid #929292;
	position: absolute;
	top: -3px;
	right: -3px;
	opacity: 0;
	z-index: 1;
}

.SortList__listItem-remove:hover { cursor: pointer; }

.SortList__listItem-remove span {
	width: 14px;
	display: block;
	text-align: center;
	position: relative;
	top: -3px;
	font-size: 14px;
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.SortList__listItem:not(.js-SortList__listItem--disabled):hover { background-color: #E6E6E6; }
.SortList__listItem:not(.js-SortList__listItem--disabled):active { background-color: #fff; }

.SortList__sortSection {
	position: relative;
	padding: 8px 10px;
	margin: 0 0 11px;
	border: 1px solid transparent;
	max-width: 650px;
}

.SortList__sortSection ~ .SortList__sortSection:before {
	background-color: #D4D4D4;
	content: "";
	display: block;
	height: 1px;
	left: 0;
	position: absolute;
	right: 0;
	top: -7px;
	width: 100%;
}

.SortList__sortSection:hover:not(:only-child) {
	border: 1px dashed #cccccc;
	cursor: move;
}

.SortList__sortSection-headerWrapper {
	position: relative;
}

.SortList__sortSection-title {
	color: #999;
	font-family: "BhrHeaderFont", "Trebuchet MS";
	-webkit-transition: color .2s ease;
	-moz-transition: color .2s ease;
	-ms-transition: color .2s ease;
	-o-transition: color .2s ease;
	transition: color .2s ease;
}

.SortList__sortSection:hover .SortList__sortSection-title,
.SortList__sortSection.ui-sortable-helper .SortList__sortSection-title { color: #222; }

.SortList__sortSection.ui-sortable-placeholder {
	height: 30px !important;
	visibility: visible !important;
}

/**
* GreenHouse Styles
**/

.SortList--greenHouse [class~="inline-bicon-"]:hover,
.SortList--greenHouse [class^="inline-bicon-"]:hover {
	background-color: #fff;
}

.SortList--greenHouse .SortList__list { min-height: 55px; }

.SortList--greenHouse .SortList__listItem.align {
	padding: 14px 13px 2px !important;
}

.SortList__listItem-label {
	display: inline-block;
	max-width: 76%;
	line-height: 20px;
	margin-top: -2px;

}

.SortList__listItem-type {
	font-family: 'Source Sans Pro',Arial, Helvetica, sans-serif;
	font-weight: normal;
	display: block;
	color: #999;
	font-size: 12px;
	height: 12px;
	text-transform: capitalize;
}

.SortList__sortSection-actions {
	opacity: 0;
	position: absolute;
	right: 5px;
	top: 5px;
	-webkit-transition: opacity .2s ease;
	-moz-transition: opacity .2s ease;
	-ms-transition: opacity .2s ease;
	-o-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.SortList__listItem-actions,
.SortList__sortSection-actions {
	opacity: 0;
	position: absolute;
	right: 5px;
	-webkit-transition: opacity .2s ease;
	-moz-transition: opacity .2s ease;
	-ms-transition: opacity .2s ease;
	-o-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.SortList__listItem-actions:hover,
.SortList__sortSection-actions:hover { cursor: default; }

.SortList__listItem-actions { top: 50%; margin-top: -10px; }
.SortList__sortSection-actions { top: 5px; }

.SortList--greenHouse .SortList__listItem:hover .SortList__listItem-actions,
.SortList--greenHouse .SortList__sortSection:hover .SortList__sortSection-actions { opacity: 1; }

.SortList--greenHouse .SortList__listItem.ui-sortable-helper .SortList__listItem-actions,
.SortList--greenHouse .SortList__sortSection.ui-sortable-helper .SortList__sortSection-actions {
	opacity: 0;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

.SortList--greenHouse .SortList__listItem{
  padding: 8px 13px !important;
  font-size: 16px;
  height: 30px;
}

/**
* Inline Sortlist
**/

.Sortlist--inline {
	min-width: 600px;
	margin: -20px 0 0 -8px;
}

.Sortlist--inline .reorderTabs1 {
	float: left;
	width: auto;
}

.Sortlist--inline .SortList__listItem {
	background-color: #fff;
	margin-right: 3px !important;
	width: auto;
	float: left;
	max-width: 250px;
	padding: 0 0 !important;
}

.Sortlist--inline .SortList__listItem.edit { background-color: #E6E6E6; }

.Sortlist--inline .SortList__listItem.ui-sortable-helper {
	width: auto !important;
	height: 29px !important;
}

.Sortlist--inline .js-SortList__placeholder {
	height: 34px !important;
	padding: 0 !important;
	visibility: visible;
}

.Sortlist--inline .reorderTabs1 .js-SortList__placeholder {
	float: left;
}

.Sortlist--inline-more {
	float: right;
	position: relative;
}

.Sortlist--inline-more__title {
	background-color: #EBEBEB;
	-webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
	color: #909090;
	display: block;
	margin-top: 7px;
	position: relative;
	text-align: center;
	width: 106px;
	padding: 9px 10px 8px;
}

.Sortlist--inline-more__title:after {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #909090;
	content: '';
	height: 0;
	left: 2px;
	position: relative;
	top: 12px;
	width: 0;
}

.Sortlist--inline .reorderTabs2 {
	border: 1px solid #919191;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	clear: both;
	float: right;
	margin: 4px 0;
	padding: 0 3px 0 3px;
	width: 125px;
}

.Sortlist--inline .reorderTabs2 .SortList__listItem {
	float: none;
	margin: 3px 0 !important;
}

/**
* Alternate Inline Styles (for employee tab) .alt
* These rules alter the main style for the customer facing Reorder Tabs
**/

.alt .js-SortList__listItem--disabled {
	border: 1px solid transparent;
}

.alt .SortList__listItem:not(.js-SortList__listItem--disabled):active,
.alt .SortList__listItem.ui-sortable-helper {
	background-color: #E6E6E6; /* Fallback*/
	background-color: rgba(225, 225, 225, 0.85);
}

.alt .SortList__listItem.ui-sortable-helper { border: 1px dashed #B0B0B0; }

.alt .SortList__Message {
	margin-top: 20px;
	float: left;
	font-size: 16px;
}

.alt .reorderTabs2 {
	-webkit-box-shadow: 0 3px 3px -1px #ddd;
	box-shadow: 0 3px 3px -1px #ddd;
}

/**
* JS-Hooks
**/

.js-SortList__listItem--disabled { color: #909090; }
.js-SortList__listItem--disabled:hover { cursor: default; }

.js-SortList__sortSection--disabled:hover { cursor: default; }

.js-SortList__placeholder {
	visibility: hidden;
	height: 36px !important;
}

.SortList--greenHouse .js-SortList__placeholder { height: 56px !important; }

.SortList__listItem.ui-sortable-helper {
	border: 1px solid #999;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0 6px 5px -5px #bbb;
	-webkit-box-shadow: 0 6px 5px -5px #bbb;
	cursor: pointer;
	overflow: hidden;
}

.SortList__sortSection.ui-sortable-helper {
	/*background-color: #fff;
	border: 1px solid #999;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0px 6px 5px -5px #bbb;
	-webkit-box-shadow: 0px 6px 5px -5px #bbb;
	color: #222;*/
	border: none;
	cursor: pointer;
	height: 25px !important;
	overflow: hidden;
	color: red;
}
.SortList__sortSection.ui-sortable-helper:hover {
	border: none;
}

.SortList__sortSection.ui-sortable-helper .SortList__sortSection-title {
	display: inline-block;
	background-color: #fff;
	border: 1px solid #999;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0 6px 5px -5px #bbb;
	padding: 6px 10px 6px 30px;
	margin-bottom: 5px;
	min-height: 14px;
}

/**
* Draggable Icon
**/

.SortList__sortSection.ui-sortable-helper .draggable-icon { display: block; }

.draggable-icon {
	border-top: 3px solid #797979;
	display: none;
	height: 15px;
	left: 20px;
	position: absolute;
	top: 15px;
	width: 15px;
}

.draggable-icon:after,
.draggable-icon:before {
	background-color: #C4C4C4;
	content: "";
	display: block;
	height: 3px;
	position: absolute;
	right: 0;
	width: 12px;
}

.draggable-icon:after { top: 2px; }
.draggable-icon:before { top: 7px; }
