.fab-Table {
	&__row {
		.Quickform &:not(.template) ~ &&--empty {
			display: none;
		}
	}

	.Quickform &__row--empty &__cell {
		fab-color: gray6;
	}
}

.Quickform {
	&__table {
		&Actions {
			float: right;
			transform: translateY(-5px);
		}

		&SubRow {
			fab-color: gray7;
			fab-text: small;
			margin-top: 2px;
		}
	}

	.fab-Table {
		&__cell {
			position: relative;

			&WithButton {
				align-items: center;
				display: flex;

				.showHideButton {
					margin-left: 4px;
				}

			}

			&--most-recent {
				&:first-of-type {
					&:before {
						content: "";
						fab-background-color: theme-lighter;
						border-radius: 50%;
						size: 8px;
						display: block;
						position: absolute;
						left: 0;

						// align with vertical center of text:
						$cellPaddingTop = 17px;
						top: 'calc(%s + 0.5em - 1px)' % ($cellPaddingTop (@height / 2));

						+jade() {
							top: calc(50% - 4px); //align with center of text which is now centered in the cell vertically
						}
					}
				}
			}
		}
	}

	// TODO: These will either go away, or be moved as BhrTable is deprecated
	.BhrTable {
		&__actions {
			white-space: nowrap;
		}

		&__header {
			&--section {
				float: left;

				+ .fieldRow {
					clear: both;
				}
			}
		}

		&__cell {
			vertical-align: middle;
			> p {
				padding: 0;
				margin: 0;
				fab-text: medium;
			}
		}
	}
}
