.SiteContent {
	{$fab-SiteContentLegacy};
}

#content {
	@extend .SiteContent;
	margin-top: 32px;
	margin-bottom: 0;

	+encore() {
		margin-top: 0;
		padding: 0;
	}
}

.page {
	{$fab-Page};
}

// TODO: this will probably change with Nav work as part of Jade
#headerSite .page,
#navMain .page,
footer .page {
	{$fab-PageWidth--base};
	{$fab-PageWidth--padding};
	{$fab-PageWidth--max};
}

#manage-nav {
	{$fab-PageWidth--bufferRight};
}

#js-setup-summary-banner {
	{$fab-PageWidth--min};
}
